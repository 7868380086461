.pt0 {
    padding-top: 0 !important
}

.pt10 {
    padding-top: 10px !important
}

.pt15 {
    padding-top: 15px !important
}

.pt15 {
    padding-top: 15px !important
}

.pt20 {
    padding-top: 20px !important
}

.pt30 {
    padding-top: 30px !important
}

.pt40 {
    padding-top: 40px !important
}

.pt50 {
    padding-top: 50px !important
}

.pt60 {
    padding-top: 60px !important
}

.pt70 {
    padding-top: 70px !important
}

.pt80 {
    padding-top: 80px !important
}

.pt100 {
    padding-top: 100px !important
}

.pr60 {
    padding-right: 60px !important
}

.pb0 {
    padding-bottom: 0
}

.pb0 {
    padding-bottom: 0px !important
}

.pb15 {
    padding-bottom: 15px !important
}

.pb20 {
    padding-bottom: 20px !important
}

.pb30 {
    padding-bottom: 30px !important
}

.pb40 {
    padding-bottom: 40px !important
}

.pb50 {
    padding-bottom: 50px !important
}

.pb60 {
    padding-bottom: 60px !important
}

.pb70 {
    padding-bottom: 70px !important
}

.pb80 {
    padding-bottom: 80px !important
}

.pb100 {
    padding-bottom: 100px !important
}

.mt0 {
    margin-top: 0px !important
}

.mt5 {
    margin-top: 5px !important
}

.mt10 {
    margin-top: 10px !important
}

.mt15 {
    margin-top: 15px !important
}

.mt20 {
    margin-top: 20px !important
}

.mt25 {
    margin-top: 25px !important
}

.mt30 {
    margin-top: 30px !important
}

.mt35 {
    margin-top: 35px !important
}

.mt40 {
    margin-top: 40px !important
}

.mt45 {
    margin-top: 45px !important
}

.mt50 {
    margin-top: 50px !important
}

.mt55 {
    margin-top: 55px !important
}

.mt60 {
    margin-top: 60px !important
}

.mt70 {
    margin-top: 70px !important
}

.mt80 {
    margin-top: 80px !important
}

.mt90 {
    margin-top: 90px !important
}

.mt-80 {
    margin-top: -80px !important
}

.mt100 {
    margin-top: 100px !important
}

.mt-120 {
    margin-top: -120px !important
}

.mt120 {
    margin-top: 100px !important
}

.mr20 {
    margin-right: 20px
}

.mr10 {
    margin-right: 10px !important
}

.mr20 {
    margin-right: 20px !important
}

.mr120 {
    margin-right: 120px !important
}

.mb20 {
    margin-bottom: 20px
}

.mb50 {
    margin-bottom: 50px
}

.ml40 {
    margin-left: 40px
}

.ml60 {
    margin-left: 60px !important
}

body {
    color: #292929;
    font-family: YakuHanJP,"Noto Sans JP",sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
}